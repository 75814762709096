import * as React from 'react';
import { Helmet } from 'react-helmet';
import PageTitleSection from '../components/structure/page-header';
import Link from '../components/elements/link';

const NotFoundPage = () => {
  const park = process.env.GATSBY_PARK,
    pageTitle = `Page not found`,
    // As this page only shows for non-existent pages, no breadcrumb
    // data will exist, so we add some in manually
    staticCrumbTrail = [
      {
        node: {
          enabled: true,
          title: 'Home',
          menu_name: 'corp-top-nav',
          link: { uri: '/', uri_alias: '/' },
          description: null,
          drupal_id: '',
          bundle: 'menu_link_content',
          drupal_parent_menu_item: null,
          weight: -50,
          external: false,
          children: [],
        },
      },
    ];

  return (
    <>
      <Helmet
        defaultTitle={`${park} | Parks Australia`}
        title={`${pageTitle} | ${park} | Parks Australia`}
      ></Helmet>

      <PageTitleSection
        title={pageTitle}
        socialMediaBlock
        breadcrumbs={staticCrumbTrail}
        summaryText
      />

      <main id="content">
        <div className="grid-wrapper">
          <div className="grid-row clearfix">
            <div className="grid-col grid-col--8 grid-col--push-2 tb-grid-col--10 tb-grid-col--push-1 ph-grid-col--12 ph-grid-col--push-0">
              <div className="copy">
                <p>Oops! The page you're looking for has been moved or doesn't exist.</p>
                <h2>Solutions</h2>
                <p>Here are some things we can try to get you back on track:</p>
                <ul>
                  <li>Use the back arrow to return to your last location.</li>
                  <li>Please check that you have entered the address correctly.</li>
                  <li>
                    <Link to="/">Click here</Link> to return to the homepage.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default NotFoundPage;
